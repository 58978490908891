import CardsHome from "./CardsHome";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import lumberBanner from "../../img/Banner/lumber-banner.jpg";

const CardRow = () => {
  return (
    <Grid
      id="services"
      container
      sx={{
        width: "100%",
        height: { xs: "75rem", sm: "80rem", md: "50rem", lg: "30rem" },
        direction: "column",
        alignItems: "center",
        component: "div",
        textAlign: "center",
        paddingBottom: "5rem",
        background: {
          xs: "rgb(19,38,44)",
          lg: `linear-gradient(0deg,rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.8)), url(${lumberBanner}) `,
        },
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ marginTop: { xs: "40px", lg: "none" } }}>
          Our Services
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CardsHome />
      </Grid>
    </Grid>
  );
};

export default CardRow;
