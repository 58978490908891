import react, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CardDataText } from "../HomeData/HomeData";
import Grid from "@mui/material/Unstable_Grid2";
import Icon from "@mui/material/Icon";

const CardsHome = () => {
  const [texts, setTexts] = useState(CardDataText);
  console.log(setTexts);
  return (
    <Grid
      container
      sx={{ textAlign: "center", width: "90%", margin: "auto" }}
      spacing={4}
    >
      {texts.map((text, index) => (
        <Grid
          xs={12}
          sm={6}
          lg={6}
          sx={{ width: { xs: "auto" }, margin: { xs: "auto", lg: "none" } }}
        >
          <Card key={index} sx={{ maxWidth: 400 }}>
            <Icon>{text.img}</Icon>
            <CardContent>
              <Typography>{text.title}</Typography>
              <Typography>{text.description}</Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CardsHome;
