import Grid from "@mui/material/Unstable_Grid2";
import { Carousel } from "react-carousel-minimal";
import Flooring1 from "../img/HomeGallery/finish-flooring1.jpeg";
import Remodeling1 from "../img/HomeGallery/finish-remodeling1.jpeg";
import Tiling1 from "../img/HomeGallery/finish-tiling1.jpeg";
import Plumbing from "../img/HomeGallery/plumbing2.jpg";
import Roofing from "../img/HomeGallery/roofing.jpg";
import Office from "../img/HomeGallery/office-finish.jpg";
import Painting from "../img/HomeGallery/painting1.jpg";

const ImageGallery = () => {
  const Data = [
    {
      image: Flooring1,
    },
    {
      image: Remodeling1,
    },
    {
      image: Tiling1,
    },
    {
      image: Plumbing,
    },
    {
      image: Roofing,
    },
    {
      image: Office,
    },
    {
      image: Painting,
    },
  ];
  return (
    <Grid
      container
      xs={12}
      lg={12}
      sx={{
        width: "fix-content",
        height: { xs: "30rem", sm: "45rem", lg: "40rem" },
        margin: "auto",
        backgroundColor: "rgb(19,38,44)",
      }}
    >
      <Grid
        xs={11}
        lg={12}
        sx={{ margin: { xs: "auto", lg: "none" }, width: { sm: "70%" } }}
      >
        <Carousel
          data={Data}
          time={4000}
          width="850px"
          height="500px"
          radius="10px"
          slideNumber={true}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover"
          thumbnails={false}
          thumbnailWidth="100px"
          style={{
            textAlign: "center",
            maxWidth: "850px",
            maxHeight: "500px",
            margin: "40px auto",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ImageGallery;
