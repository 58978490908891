import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import MapIcon from "@mui/icons-material/Map";
import Box from "@mui/material/Box";
import Logo from "../img/logo.png";

const BottomNavigation = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "rgb(19,38,44)",
        color: "white",
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          backgroundColor: "rgb(19, 38, 44)",
          width: "80%",
          height: { sx: "55rem", lg: "20rem" },
          alignItems: "center",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <Grid xs={12} lg container>
          <img src={Logo} alt="logo" style={{ width: "20%", margin: "auto" }} />
          <Typography
            variant="h5"
            sx={{
              margin: "auto",
              flexGrow: 5,
              textAlign: { sx: "center", md: "left" },
            }}
          >
            Hammerz Construction
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="space-around"
            margin="auto"
          >
            <Button href="#home">Home |</Button>
            <Button href="#services">Services |</Button>
            <Button href="#about">About |</Button>
            <Button href="#contact">Contact |</Button>
          </Stack>
        </Grid>
        <Grid xs={12} lg={6}>
          <Typography variant="h5" sx={{ marginBottom: "10px" }}>
            Area Location
          </Typography>
          <Stack direction="row" spacing={10} justifyContent="center">
            <Typography>
              <MapIcon sx={{ marginRight: "5px" }} />
              Dalton
            </Typography>
            <Typography>
              <MapIcon sx={{ marginRight: "5px" }} />
              Chattanooga
            </Typography>
            <Typography>
              <MapIcon sx={{ marginRight: "5px" }} />
              Chatsworth
            </Typography>
          </Stack>
        </Grid>
        <Grid xs={12} lg>
          <Typography variant="h5" sx={{ marginBottom: "10px" }}>
            Services
          </Typography>
          <Stack spacing={1}>
            <Typography>Plumbing</Typography>
            <Typography>Painting</Typography>
            <Typography>Tiling Installation</Typography>
            <Typography>Drywall Installation</Typography>
            <Typography>Renovation and Remodeling</Typography>
            <Typography>Roofing Installation</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BottomNavigation;
