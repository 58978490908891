import Grid from "@mui/material/Unstable_Grid2";
import heroBanner from "../../img/Banner/about-main.jpg";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const Hero = () => {
  return (
    <Grid
      id="home"
      container
      sx={{
        component: "div",
        backgroundImage: `url(${heroBanner})`,
        width: "100%",
        height: "30rem",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{
          backgroundColor: "rgba(24, 26, 27, 0.9)",
          borderColor: "rgb(140, 130, 115)",
          maxWidth: { xs: "90%", sm: "60%" },
          margin: "auto",
          color: "white",
        }}
      >
        <CardContent>
          <Typography
            variant="h3"
            sx={{ fontSize: { xs: "2rem", sm: "3rem" } }}
          >
            Hammerz Construction
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              textAlign: { xs: "center", sm: "left" },
            }}
          >
            Committed to excellence in every construction and renovation
            endeavor, with over 15 years of industry expertise, we excel in
            laminate flooring installation, plumbing, and remodeling.
          </Typography>
        </CardContent>
        {/* <CardContent>
          <Typography variant="h4">
            Quality Work at an Affordable Price
          </Typography>
          <Typography variant="h6">
            Hammerz Construction is committed to keeping client satisfaction a
            top priority by developing a plan that will work with clients so
            that we may deliver the desired results. We can guarantee that
            Hammerz Construction will work with you every step of the way and
            have it done at an affordable price.
          </Typography>
        </CardContent> */}
      </Card>
    </Grid>
  );
};

export default Hero;
