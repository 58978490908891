import Navigation from "./Components/Navigation";
import Home from "./Pages/Home";
import Box from "@mui/material/Box";
import BottomNavigation from "./Components/BottomNavigation";
import Footer from "./Components/Footer";
import MiniNav from "./Components/MiniNav";

function App() {
  return (
    <Box>
      <Navigation />
      <Home />
      <MiniNav />
      <BottomNavigation />
      <Footer />
    </Box>
  );
}

export default App;
