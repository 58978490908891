import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const NavTabs = () => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{ display: { xs: "none", sm: "block" } }}
    >
      <Button href="#home" color="inherit">
        Home
      </Button>
      <Button href="#services" color="inherit">
        Services
      </Button>
      <Button href="#about" color="inherit">
        About
      </Button>
      <Button href="#contact" color="inherit">
        Contact
      </Button>
    </Stack>
  );
};

export default NavTabs;
