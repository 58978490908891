import react from "react";
import Grid from "@mui/material/Unstable_Grid2";

const Introduction = () => {
  return (
    <Grid>
      <Grid></Grid>
    </Grid>
  );
};

export default Introduction;
