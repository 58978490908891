import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import WhyBanner from "../../img/Banner/why-banner.jpg";

const Why = () => {
  return (
    <Grid
      id="about"
      container
      columns={{ lg: 16 }}
      sx={{
        backgroundColor: "rgb(19, 38, 44)",
        width: "100%",
        height: "40rem",
      }}
    >
      <Grid
        xs={12}
        sm={6}
        lg={10}
        sx={{
          backgroundColor: "rgba(0, 0, 0, .8)",
          border: "1px solid yellow",
          width: { xs: "90%", lg: "40%" },
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" sx={{ color: "white" }}>
          Why Choose Hammerz Construction?
        </Typography>
        <Typography sx={{ color: "white" }}>
          Here at Hammerz Construction Dalton Ga, our mission statement is to be
          able to provide excellent client satisfaction while mainting our
          integrity. By choosing Hammerz Construction, we'll bring your vision
          to life by delivering quality work at an affordable price, and on
          time.
        </Typography>
      </Grid>
      <Grid
        container
        xs={12}
        sm={6}
        lg={5}
        sx={{ alignSelf: "center", margin: "auto" }}
      >
        <img
          src={WhyBanner}
          style={{
            width: "80%",
            margin: "auto",
            textAlign: "center",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Why;
