import react from "react";
import Toolbar from "@mui/material/Toolbar";

const Divider = () => {
  return (
    <div>
      <div style={{ height: "30px", backgroundColor: "rgb(0,0,0)" }}></div>
    </div>
  );
};

export default Divider;
