import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
//Speed Dial
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
//Icons
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HomeRepairServiceOutlinedIcon from "@mui/icons-material/HomeRepairServiceOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";

const style = {
  backgroundColor: "rgb(19, 38, 44)",
  boxShadow: 24,
  p: 4,
  margin: "auto",
  width: "100%",
};

const actions = [
  {
    icon: <HomeOutlinedIcon />,
    name: "Home",
    link: "#home",
  },
  {
    icon: <HomeRepairServiceOutlinedIcon />,
    name: "Services",
    link: "#services",
  },
  { icon: <InfoOutlinedIcon />, name: "About", link: "#about" },
  { icon: <ContactPhoneOutlinedIcon />, name: "Contact", link: "#contact" },
];

const MiniNav = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Tooltip
        sx={{
          position: "fixed",
          bottom: 20,
          left: 20,
          display: { sx: "block", md: "none" },
        }}
        // onClick={(e) => setOpen(true)}
      >
        <SpeedDial ariaLabel="Mobile Link" icon={<SpeedDialIcon />}>
          {actions.map((action) => (
            <SpeedDialAction
              href={action.link}
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipPlacement="right"
            />
          ))}
        </SpeedDial>
      </Tooltip>
    </>
  );
};

export default MiniNav;
