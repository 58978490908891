import NavTabs from "./NavTabs";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import ToolBar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../img/logo.png";
import Box from "@mui/material/Box";
const Navigation = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ background: "rgb(19, 38, 44)" }}>
        <Container>
          <ToolBar sx={{ textAlign: { xs: "center", sm: "left" } }}>
            <img src={Logo} alt="logo" style={{ width: "10%" }} />
            <Typography sx={{ flexGrow: 5 }}>Hammerz Construnction</Typography>
            <NavTabs />
          </ToolBar>
        </Container>
      </AppBar>
      <ToolBar />
    </Box>
  );
};

export default Navigation;
