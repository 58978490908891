import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const SecondRow = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgb(19, 38, 44)",
        width: "100%",
        height: { xs: "20rem", lg: "30rem" },
      }}
    >
      <Card
        variant="outlined"
        sx={{
          backgroundColor: "rgba(0, 0, 0, .8)",
          border: "1px solid yellow !important",
          maxWidth: { xs: "90%", lg: "50%" },
          margin: "auto",
          textAlign: "center",
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            sx={{ fontSize: { xs: "2rem", lg: "3rem" }, color: "white" }}
          >
            Quality Work at an Affordable Price
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: { xs: "1rem", lg: "1.25rem" }, color: "white" }}
          >
            At Hammerz Construction, our commitment is to prioritize the
            satisfaction of our clients. We achieve this by collaboratively
            developing a tailored plan that aligns perfectly with your vision,
            ensuring the delivery of your desired results. Throughout the entire
            journey, rest assured that Hammerz Construction will stand by your
            side, working hand in hand with you to bring your project to
            realization.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default SecondRow;
