import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Form from "../../Components/Form";

const Contact = () => {
  //   // Imports the Recaptchaenterprise library
  //   const { RecaptchaEnterpriseServiceClient } =
  //     // eslint-disable-next-line node/no-missing-require
  //     require("@google-cloud/recaptcha-enterprise").v1;

  //   // Instantiates a client
  //   const recaptchaenterpriseClient = new RecaptchaEnterpriseServiceClient();

  //   async function callListKeys() {
  //     // Construct request
  //     const request = {
  //       parent,
  //     };

  //     // Run request
  //     const iterable = await recaptchaenterpriseClient.listKeysAsync(request);
  //     for await (const response of iterable) {
  //       console.log(response);
  //     }
  //   }

  //   callListKeys();
  //   function onClick(e) {
  //     e.preventDefault();
  //     grecaptcha.enterprise.ready(async () => {
  //       const token = await grecaptcha.enterprise.execute(
  //         "6LdWovMnAAAAABPBKUvFR3Z-dMhwnD-1bvBGIiUR",
  //         { action: "LOGIN" }
  //       );
  //       // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
  //       // reCAPTCHA Enterprise to the end user's browser.
  //       // This token must be validated by creating an assessment.
  //       // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
  //     });
  //   }
  return (
    <Grid
      id="contact"
      container
      spacing={2}
      columns={{ lg: 16 }}
      sx={{
        width: "100%",
        alignSelf: "center",
        height: { lg: "50rem" },
        alignItems: "center",
      }}
    >
      <Grid xs={12} lg={8} sx={{ marginTop: { xs: "50px", lg: "none" } }}>
        <Card sx={{ width: "60%", margin: "auto" }}>
          <CardContent>
            <Typography variant="h4">Contact</Typography>
            <Typography>
              At Hammerz Construction, located in Dalton, GA, our unwavering
              mission is to go above and beyond to ensure our clients' complete
              satisfaction while upholding the highest standards of integrity.
              When you opt for Hammerz Construction, you're choosing a team that
              will turn your dream into reality. We're committed to delivering
              top-notch craftsmanship at an affordable price.
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid xs={12} lg={8}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", marginBottom: "2rem" }}
        >
          Request a Consultation
        </Typography>
        <Form />
      </Grid>
    </Grid>
  );
};

export default Contact;
