import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";

const Form = () => {
  const [state, handleSubmit] = useForm("xwkwnaad");
  const [textInput, setTextInput] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const userInputHandler = (e) => {
    setFName("");
    setLName("");
    setPhoneNumber("");
    setEmail("");
    setTextInput("");
  };

  if (state.succeeded) {
    return <p>Thanks for joining!</p>;
  }
  return (
    <Box
      component="form"
      id="fs-frm"
      name="construction-form"
      action="https://formspree.io/f/xwkwnaad"
      method="post"
      accept-charset="utf-8"
      sx={{ maxWidth: "80%", margin: "auto" }}
    >
      <Grid
        container
        spacing={4}
        xs={12}
        sx={{ margin: "auto", textAlign: "center" }}
      >
        <Grid xs={12} lg={6}>
          <TextField
            name="First Name"
            label="First Name"
            required
            value={fName}
            sx={{ width: { xs: "100%", lg: "80%" } }}
            onChange={(e) => {
              setFName(e.target.value);
            }}
          />
          <ValidationError prefix="FName" field="fname" errors={state.errors} />
        </Grid>
        <Grid xs={12} lg={6}>
          <TextField
            name="Last Name"
            label="Last Name"
            required
            value={lName}
            sx={{ width: { xs: "100%", lg: "80%" } }}
            onChange={(e) => {
              setLName(e.target.value);
            }}
          />
          <ValidationError prefix="LName" field="lname" errors={state.errors} />
        </Grid>
        <Grid xs={12} lg={6}>
          <TextField
            label="Phone"
            name="Phone"
            placeholder="Optional"
            value={phoneNumber}
            sx={{ width: { xs: "100%", lg: "80%" } }}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
          />
          <ValidationError prefix="Phone" field="phone" errors={state.errors} />
        </Grid>
        <Grid xs={12} lg={6}>
          <TextField
            name="Email"
            label="Email"
            required
            value={email}
            sx={{ width: { xs: "100%", lg: "80%" } }}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
        </Grid>

        <Grid xs={12}>
          <TextField
            name="Message"
            label="Message"
            required
            value={textInput}
            multiline
            rows={12}
            sx={{ width: { xs: "100%", lg: "90%" } }}
            onChange={(e) => {
              setTextInput(e.target.value);
            }}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />
        </Grid>
        <Grid xs>
          <Button
            type="submit"
            variant="contained"
            sx={{ margin: "auto", width: "30%" }}
            disabled={state.submitting}
            onSubmit={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Form;
