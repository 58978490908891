import Hero from "./HomeComponents/Hero";
import CardRow from "./HomeComponents/CardRow";
import Why from "./HomeComponents/Why";
import Contact from "./HomeComponents/Contact";
import Box from "@mui/material/Box";
import SecondRow from "./HomeComponents/SecondRow";
import Divider from "../Components/Divider";
import ImageGallery from "../Components/ImageGallery";
import Introduction from "./HomeComponents/Introduction";

const Home = () => {
  return (
    <Box>
      <Hero id="home" />
      <SecondRow />
      <ImageGallery />
      <CardRow />
      <Divider />
      <Why />
      <Introduction />
      <Divider />
      <Contact />
    </Box>
  );
};

export default Home;
