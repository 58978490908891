import ConstructionIcon from "@mui/icons-material/Construction";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import CarpenterIcon from "@mui/icons-material/Carpenter";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";

export function CardDataText() {
  return [
    {
      title: "PAINTING",
      description:
        "When it comes to painting, we leave no room for imperfection. We inspect every layer of coating to ensure there are no dark, light, or missed spots. Our duties extend to employing advanced techniques, such as sandpaper and scrupulous scraping, to create a seamlessly smooth surface on both interior and exterior spaces. ",
      img: <ImagesearchRollerIcon />,
    },
    {
      title: "FLOORING",
      description:
        "Our expertise lies in the artistry of laminate, hardwood, and vinyl flooring. Whether you desire laminate or the durability of hardwood, rest assured that Hammerz Construction is your trusted source for all your flooring needs. We've got every type of flooring covered to ensure your vision becomes a reality.",
      img: <ConstructionIcon />,
    },
    {
      title: "REMODELING",
      description:
        "At Hammerz Construction, we understand that this desire for transformation can be both exciting and significant. With a wealth of expertise accumulated over 15 years, Hammerz Construction possesses the craftsmanship required to fulfill all your remodeling aspirations, no matter how grand they may be.",
      img: <HomeRepairServiceIcon />,
    },
    {
      title: "DRYWALL INSTALLATION",
      description:
        "We take on the task of refining your surfaces, tending to every detail on both ceilings and walls. Our dedicated workmanship ensures that imperfections are eradicated, leaving behind a smooth, pristine canvas for your creative ideas. Trust us to bring your vision to life with our precision and craftsmanship.",
      img: <CarpenterIcon />,
    },
  ];
}
