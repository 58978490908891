import react from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const Footer = () => {
  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgb(33, 55, 75)",
        height: "4rem",
        color: "white",
      }}
    >
      <Container sx={{ textAlign: "end", margin: "auto" }}>
        <Typography>Hammerz Construction</Typography>
      </Container>
    </Grid>
  );
};

export default Footer;
